import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Pagination from '../components/Pagination'
import { Strapi_Enum_Mediapost_Type } from '../_generated/codegen/graphqlTypes'
import { IMediaPostShort } from '../templates/MediaPage'
import { mediaPagePathBuilder } from '../templates/MediaPage.context'
import MediaCard from './MediaCard'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.secondary};
`

const List = styled.ul`
  border-top: 2px solid ${({ theme }) => theme.colors.muted};
  border-bottom: 2px solid ${({ theme }) => theme.colors.muted};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 23px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 21px 0;
    gap: 26px;
  }
`

const PaginationWrapper = styled(Pagination)`
  padding-bottom: 10px;
`

const useTitle = (type?: Strapi_Enum_Mediapost_Type) => {
  return useMemo(() => {
    switch (type) {
      case Strapi_Enum_Mediapost_Type.CommuniqueDePresse:
        return 'Communiqué de Presse'
      case Strapi_Enum_Mediapost_Type.ArticleDePresse:
        return 'Articles Presse'
      default:
        return 'Actualité Presse'
    }
  }, [type])
}

interface MediaListProps {
  items: IMediaPostShort[]
  numPages: number
  currentPage: number
  type?: Strapi_Enum_Mediapost_Type
}

const MediaList: React.FC<MediaListProps> = ({
  items,
  numPages,
  currentPage,
  type,
}) => {
  const title = useTitle(type)
  const pathBuilder = useCallback(
    (i: number) => mediaPagePathBuilder(i, type),
    [type]
  )

  return (
    <Container>
      <Title>{title}</Title>
      <List>
        {items.map((item, index) => (
          <li key={index}>
            <MediaCard item={item} />
          </li>
        ))}
      </List>
      <PaginationWrapper
        numPages={numPages}
        currentPage={currentPage}
        pathBuilder={pathBuilder}
      />
    </Container>
  )
}

export default MediaList
