import { graphql, PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import MediaAbout from '../components/MediaAbout'
import MediaContainer from '../components/MediaContainer'
import MediaList from '../components/MediaList'
import MediaTabs from '../components/MediaTabs'
import {
  MediaPostsQuery,
  Strapi_Enum_Mediapost_Type,
} from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import { Unpacked } from '../utils/Unpacked'
import { MediaPageContext } from './MediaPage.context'
import { Helmet } from 'react-helmet'

const useReducedAbout = (type?: Strapi_Enum_Mediapost_Type) => {
  return useMemo(() => type !== undefined, [type])
}

const useItems = (data: MediaPostsQuery): IMediaPostShort[] => {
  return useMemo(() => data['strapi']['mediaPosts']?.filter(notEmpty) ?? [], [])
}

const MediaPage: React.FC<PageProps<MediaPostsQuery, MediaPageContext>> = ({
  data,
  pageContext,
}) => {
  const reducedAbout = useReducedAbout(pageContext.type)
  const items = useItems(data)

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title="Save, un expert de la réparation mobile !"
          content={`La presse parle de nous. Retrouvez toute l'actualité de Save dans les médias en France et à l'International`}
        />
      </Helmet>

      <MediaContainer reducedAbout={reducedAbout}>
        <MediaTabs type={pageContext.type} />
        <MediaAbout reduced={reducedAbout} />
        <MediaList
          items={items}
          currentPage={pageContext.currentPage}
          numPages={pageContext.numPages}
          type={pageContext.type}
        />
      </MediaContainer>
    </>
  )
}

export type IMediaPostShort = NonNullable<
  Unpacked<NonNullable<MediaPostsQuery['strapi']['mediaPosts']>>
>

export const query = graphql`
  query MediaPosts($start: Int!, $limit: Int!, $where: Strapi_JSON) {
    strapi {
      mediaPosts(
        start: $start
        limit: $limit
        sort: "published_at:desc"
        where: $where
      ) {
        id
        slug
        title
        type
        published_at
        description
        cover {
          url
          name
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 300)
            }
          }
        }
      }
    }
  }
`

export default MediaPage
